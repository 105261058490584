<template>
    <div class="text-center pos-r">
        <h5 class="font-inter-extra-bold text-2-trans text-lg mb-3">0{{ serial }}</h5>
        <div class="pos-r">
            <span class="line-right"></span>
            <h6 class="mb-3 font-inter-semi-bold fs--1">{{ title }}</h6>
            <p :class="{'border-right':borderRight}" class="pr-3 font-inter-medium fs--2">
                {{ content }}
            </p>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ShortDetailsSection',
    props: {
        serial: {
            type: String,
            default: ''
        },
        borderRight: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">
.large-text {
    font-weight: 900;
}

.text-lg {
    font-size: 2.5rem;
    //font-weight: 1200;
    //color: var(--color-white);
    font-weight: 900;
}

.text-2-trans{
    color:rgba(82,82,82,0.25);
}

//.border-right {
//    border-right: 2px solid var(--color-success-400);
//}

.line-right {
    position: absolute;
    right: 0;
    width: 1.1px;
    height: 3.5rem;
    transform: translateY(20%);
    //background-color: var(--bottom-tag-color);
    background-color: rgba(127,180,25,0.6);
}
</style>
