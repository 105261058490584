<template>
    <div class="b-1 bs-3 mb-4 m-0">
        <div class="text-decoration-none border-r-0">
            <div class="mb-3 bg-4">
                <div class="card-min-h">
                    <img :src="img" alt="" class="img-fluid mb-0">
                    <!--                <img-->
                    <!--                    src="https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"-->
                    <!--                    alt="" class="img-fluid mb-0">-->
                </div>
            </div>
            <div class="text-center pt-4 pb-2 min-h-17re">
                <div class="bottom-tag">
                    <h5 class="mb-3 font-inter-bold fs-lg-1">{{ title }}</h5>
                </div>
                <p class="mt-4 px-2 px-lg-3 font-inter-medium text-3 text-left fs--1">{{ content }}</p>
            </div>
        </div>
    </div>
</template>
<script>
// import ShortSection from './ShortSection';

export default {
    name: 'FeatureCard',
    components: {},
    props: {
        title: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: Image
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    }
};
</script>
<style lang="scss" scoped>
.card-min-h {
    position: relative;
    height: 14rem;
    @media (min-width: 992px) {
        height: 9rem;
    }

    img {
        width: 100% !important;
        height: 100% !important;
    }
}

.border-left {
    border-left: 1px solid var(--text-2);
}

.bottom-tag {
    &::after {
        content: '';
        position: absolute;
        width: 10%;
        transform: translateX(-50%);
        height: 2px;
        //background-color: #424c1f;
        background-color: var(--bottom-tag-color);
        //margin-top: 1rem;
    }
}

.min-h-17re {
    min-height: 12rem !important;
    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 12rem !important;
    }
    @media (min-width: 992px) {
        min-height: 17rem !important;
    }
}
</style>
