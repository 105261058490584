<template>
    <div class="row bg-6-trans pos-r pb-4">
        <div class="col-12 col-lg-6 col-md-6 pos-r">
            <div class="abs-top p-lg-6 py-5">
                <image-with-shadow class="" :image="details.image"></image-with-shadow>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6">
            <div class="pt-lg-6 pb-lg-7 pr-lg-9 p-3 right-layer top-layer">
                <h4 class="mb-5 font-inter-semi-bold">Our vision and mission</h4>
                <p class="font-inter-regular text-4 align-justify">
                    {{ details.paragraph_1 }}
                    <br><br>
                    {{ details.paragraph_2 }}
                </p>
            </div>

        </div>
    </div>
</template>

<script>
import ImageWithShadow from '@components/ImageWithShadow';
import visionMissionImg from '../../../assets/web/About us/vistion-and-mission.jpg';

export default {
    name: 'MissionAndVision',
    components: { ImageWithShadow },
    props: {
        details: { type: Object, default: null }
    },
    data () {
        return {
            visionMissionImg: visionMissionImg
        };
    }
};
</script>

<style scoped lang="scss">
.abs-top {
    @media (min-width: 992px) {
        position: absolute;
        top: -8rem;
    }

}

.right-layer {
    @media (min-width: 992px) {
        ::after {
            content: '';
            position: absolute;
            right: -1.3rem;
            //right: 0;
            top: 0;
            width: 1.3rem;
            height: 4rem;
            background-color: var(--color-primary);
        }
    }
}

.top-layer {
    @media (min-width: 992px) {
        ::before {
            content: '';
            position: absolute;
            //right: -0.8rem;
            right: -1.3rem;
            top: -1.3rem;
            //top: -0.8rem;
            width: 5rem;
            height: 1.3rem;
            background-color: var(--color-primary);
        }
    }
}
</style>
