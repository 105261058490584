<template>
    <div class="features-bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <heading-with-info class="" title="Our Features"
                                       :info="details.text"></heading-with-info>
                </div>
                <div class="col-12 col-lg-3 col-md-6" v-for="(item,i) in items" :key="i">
                    <feature-card :title="item.title" :img="item.image"
                                  :content="item.content"></feature-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';
import FeatureCard from '../../../components/about/FeatureCard';
import img1 from '../../../assets/web/About us/features/feature1.jpg';
import img2 from '../../../assets/web/About us/features/feature2.jpg';
import img3 from '../../../assets/web/About us/features/feature3.jpg';
import img4 from '../../../assets/web/About us/features/feature4.jpg';

export default {
    name: 'OurFeatures',
    props: {
        details: { type: Object, default: null }
    },
    components: { FeatureCard, HeadingWithInfo },
    data () {
        return {
            items: [
                {
                    title: 'Genuine Property',
                    content: 'The properties which we offer are owned by well-known banks and RBI-registered NBFCs, so  quality and authenticity can\'t be compromised ',
                    image: img3
                },
                {
                    title: 'Help and Support',
                    content: 'Our 24*7 support team provides seamless support to your queries and rocket fast solutions in your difficult times ',
                    image: img4
                },
                {
                    title: 'Safe and secured',
                    content: 'We are obliged to keep your transactions and  personal details very confidential in a highly solicited platform that is technically flawless ',
                    image: img1
                },
                {
                    title: 'Fast yet reliable',
                    content: 'With the technical specifications we offer  the process of e Auction, bidding and the support systems perform actions in a flash of a second the secured database keeps your information confidential and safe ',
                    image: img2
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
.features-bg {
    background: rgb(243, 243, 243);
    background: linear-gradient(90deg, rgba(243, 243, 243, 1) 10%, rgba(247, 247, 247, 1) 96%);
}
</style>
