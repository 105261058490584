<template>
    <section v-if="dataLoading" class="py-lg-9">
        <div class="text-center pt-7 pb-7">
            <loading-animation class="text-primary text-5x"/>
        </div>
    </section>
    <div v-else class="about">
        <inner-banner heading="About Us"
                      banner-type="about" :image="details.about_us.banner_image"
                      :content="details.about_us.about_text">

        </inner-banner>
        <div class="container py-6">
            <div class="row" v-if="details.about_us">
                <div class="col-12 col-lg-6">
                    <!--                    <div class="text-center abs-left-res left-12p">-->
                    <div class="text-center left-12p">
                        <p class="pl-lg-2 fl-x-res fl-a-c">
                            <!--                            <i class="fa fa-quote-left fa-1x text-primary"></i>-->
                            <img src="../assets/mobile/home/icons/quote-left-alter-48.png" class="top--02re icon-md" alt="">
                            <span class="font-inter-medium text-4 pl-lg-2">{{ details.about_us.text_1 }}</span>
                            <img src="../assets/mobile/home/icons/quote-left-alter-48.png" class="top--02re icon-md ml-1 rotate-180" alt="">

                            <!--                            <i class="fa fa-quote-right fa-1x text-primary"></i>-->
                        </p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <p class="font-inter-medium mr-lg-6 text-4 align-justify">
                        {{ details.about_us.text_2 }}
                    </p>
                </div>
                <div class="col-12 mt-6">
                    <mission-and-vision v-if="details.vision_and_mission"
                                        :details="details.vision_and_mission"></mission-and-vision>
                </div>
                <div class="col-12 mt-5">
                    <how-it-works v-if="details.how_it_works.length!==0" :details="details.how_it_works"></how-it-works>
                </div>
            </div>
        </div>
        <div class="py-5 features-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <our-features v-if="details.our_features" :details="details.our_features"></our-features>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import InnerBanner from '@components/InnerBanner';
import MissionAndVision from '@components/about/sections/MissionAndVision';
import HowItWorks from '@components/about/sections/HowItWorks';
import OurFeatures from '../components/about/sections/OurFeatures';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'About',
    components: { OurFeatures, HowItWorks, MissionAndVision, InnerBanner },
    data () {
        return {
            details: '',
            dataLoading: false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.aboutUs);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped lang="scss">
    .abs-top {
        @media (min-width: 992px) {
            position: absolute;
            top: -5rem;
        }

    }

    .features-bg {
        background: rgb(243, 243, 243);
        background: linear-gradient(90deg, rgba(243, 243, 243, 1) 10%, rgba(247, 247, 247, 1) 96%);
    }
</style>
