<template>
    <div class="row py-5">
        <div class="col-12">
            <heading-with-info class="" :bottom-tag="false" title="How It Works"
                               info="We have made it easy for you in four steps. "></heading-with-info>
        </div>
        <div class="col-12 col-lg-3 col-md-6 mt-4" v-for="(item, index) in details" :key="index">
            <short-details-section :serial="index + 1" :title="item.title"
                                   :content="item.description"></short-details-section>
        </div>
    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';
import ShortDetailsSection from '@components/about/ShortDetailsSection';

export default {
    name: 'HowItWorks',
    props: {
        details: { type: Array, default: null }
    },
    components: { ShortDetailsSection, HeadingWithInfo }
};
</script>

<style scoped>

</style>
