<template>
<!--    <img class="square-shadow img-fluid" src="https://images.unsplash.com/photo-1517732306149-e8f829eb588a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80" alt="">-->
    <img class="square-shadow img-fluid" :src="image" alt="">
</template>

<script>
export default {
    name: 'ImageWithShadow',
    props: {
        image: {
            type: Image
        }
    }
};
</script>

<style scoped lang="scss">
.square-shadow{
    //box-shadow: 0.5rem 0.5rem #e3e3e3;
    //box-shadow: 1.2rem 1.2rem rgba(227,227,227,0.6);
    box-shadow: 1.7rem 1.7rem rgba(181,176,176,0.15);
}
</style>
